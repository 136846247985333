import fetchData from './scanditService';

const cache: any = {};

const getFromCache = (code: string) => {
  return cache[code];
};

const setKeyToCache = (code: string, value: any | undefined) => {
  // eslint-disable-next-line fp/no-mutation
  cache[code] = value;
};

const deleteCode = (code: string) => {
  // eslint-disable-next-line fp/no-delete
  delete cache[code];
};

const getInfoCodes = (codes: string[]) => {
  const infoCodes = codes.map((code) => ({ [code]: getFromCache(code) }));

  if (infoCodes.every((code) => !!code[Object.keys(code)[0]])) {
    return infoCodes.map((code) => code[Object.keys(code)[0]]);
  }

  const emptyCodes = infoCodes
    .filter((code) => !code[Object.keys(code)[0]])
    .map((code) => Object.keys(code)[0])
    .filter((code) => !(code in cache));

  if (emptyCodes.length === 0) {
    return false;
  }

  emptyCodes.forEach((code) => {
    // eslint-disable-next-line unicorn/no-useless-undefined
    setKeyToCache(code, undefined);
  });

  fetchData
    .execute(emptyCodes)
    // eslint-disable-next-line promise/prefer-await-to-then, promise/always-return
    .then((response) => {
      response.forEach((item: any) => {
        setKeyToCache(item.alternativeId, item);
      });
    })
    .catch(() => {
      emptyCodes.forEach((code) => {
        deleteCode(code);
      });
    });

  return false;
};

export default getInfoCodes;
