/* eslint-disable import/prefer-default-export,default-param-last */
import {
  ScanditAction,
  ADD_CONTENT_LOGO_STATE,
  ADD_KEY_LOGO_STATE,
  SET_UPLOAD_STATUS_STATE,
} from '../actionTypes/scandit-action-types';

const initialState: any = {
  content: [],
  isUploaded: false,
};

export const contentLogosStateReducer = (
  state: any = initialState,

  action: ScanditAction,
) => {
  if (action.type === ADD_CONTENT_LOGO_STATE) {
    return {
      ...state,

      // eslint-disable-next-line array-callback-return
      ...state.content.map((item: any) => {
        if (item.id === action.value.id) {
          // eslint-disable-next-line fp/no-mutation,no-param-reassign
          item.value = action.value.value;
        }
      }),
    };
  }

  if (action.type === ADD_KEY_LOGO_STATE) {
    return {
      ...state,
      content: [...state.content, { id: action.id, value: '' }],
    };
  }

  if (action.type === SET_UPLOAD_STATUS_STATE) {
    return {
      ...state,
      isUploaded: action.status,
    };
  }

  return state;
};
