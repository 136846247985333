/* eslint-disable max-params,unicorn/consistent-function-scoping */
import React from 'react';
import { useSelector } from 'react-redux';

import {
  ItemInfoPremiumProperties,
  RequestLogoProperties,
} from '../../interfaces/interfaces-codes-data';
import { OVERLAY_SETTINGS_STYLE } from '../../core/constants/constants';
import { logoContentSelector } from '../../core/selectors/selectors';

import LogoComponent from './item-logo-component';
import styles from './item-result-info-premium.module.scss';

const findLogo = (id: string, sponsorLogosContent: any[]) => {
  const currentLogo = sponsorLogosContent.find(
    (logoElement: RequestLogoProperties) => logoElement.id === id,
  );

  if (!currentLogo) {
    return false;
  }

  return currentLogo.value;
};

const ItemResultInfoPremium = function ItemResultInfoPremium({
  scanedCodeEventName,
  scanedCodeDescription,
  scanedCodeEventSponsorLogo,
  isShowSponsorsLogo,
  styleReference,
}: ItemInfoPremiumProperties): React.ReactElement {
  const altImage: string = 'Sponsor logo';
  const sponsorsTitle: string = 'Sponsored by';

  const sponsorLogosContent: any = useSelector(logoContentSelector);
  const setStyle = (
    isItalic: boolean,
    isBold: boolean,
    fontFamily: string,
    color: string,
    fontSize: string,
  ) => {
    return {
      color,
      fontFamily,
      fontSize: `${fontSize}px`,

      fontStyle: isItalic
        ? OVERLAY_SETTINGS_STYLE.FONT_STYLE_ITALIC
        : OVERLAY_SETTINGS_STYLE.FONT_STYLE_NORMAL,

      fontWeight: isBold
        ? OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_BOLD
        : OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_NORMAL,
    };
  };

  return (
    <div className={styles['a-item-info-premium']}>
      <div
        className={styles['a-item-info-premium__top-part']}
        style={setStyle(
          styleReference.nameFont.isItalic,
          styleReference.nameFont.isBold,
          styleReference.nameFont.name,
          styleReference.ownerNameColor,
          styleReference.nameFont.fontSize,
        )}
      >
        {scanedCodeEventName}
      </div>

      <div
        className={styles['a-item-info-premium__bottom-part']}
        style={setStyle(
          styleReference.statementFont.isItalic,
          styleReference.statementFont.isBold,
          styleReference.statementFont.name,
          styleReference.ownerStatementColor,
          styleReference.nameFont.fontSize,
        )}
      >
        <div className={styles['a-item-info-premium__bottom-part-top']}>
          {scanedCodeDescription}
        </div>

        {isShowSponsorsLogo && (
          <div className={styles['a-item-info-premium__bottom-part-bottom']}>
            <div
              className={styles['a-item-info-premium__title']}
              style={{
                color: styleReference.ownerStatementColor,
              }}
            >
              {sponsorsTitle}
            </div>

            {scanedCodeEventSponsorLogo.length > 0 &&
              scanedCodeEventSponsorLogo.map((element: any) => (
                <LogoComponent
                  key={element}
                  altImage={altImage}
                  source={findLogo(element, sponsorLogosContent.content)}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemResultInfoPremium;
