/* eslint-disable no-shadow,@typescript-eslint/no-shadow,react/jsx-no-literals,jsx-a11y/media-has-caption */
import React, { useState, useEffect, useMemo } from 'react';
import { BrowserQRCodeReader } from '@zxing/library';

import LoaderComponent from '../atoms/loader-component';
import { SCAN_TYPE } from '../../core/constants/constants';

import styles from './barcode-picker.module.scss';

const ZxingScan = function ZxingScan({
  onScan,
  setDataScan,
  scannerType,
}: any): React.ReactElement {
  const [selectedDeviceId, setSelectedDeviceId] = useState('');
  const [isMakeScreenshot] = useState<boolean>(false);

  const codeReader = useMemo(() => new BrowserQRCodeReader(), []);

  const setupDevices = (videoInputDevices: any) => {
    if (videoInputDevices.length > 1) {
      setSelectedDeviceId(videoInputDevices[1].deviceId);
    } else {
      setSelectedDeviceId(videoInputDevices[0].deviceId);
    }
  };

  const initialize = async () => {
    const init = await codeReader.getVideoInputDevices();

    setupDevices(init);
  };

  useEffect(() => {
    initialize();

    if (scannerType === SCAN_TYPE.PREMIUM) {
      codeReader.stopContinuousDecode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannerType]);

  const decodeContinuously = (selectedDeviceId: any) => {
    codeReader.decodeFromInputVideoDeviceContinuously(
      selectedDeviceId,
      'video',
      (result) => {
        if (result) {
          const scanResult: any = result.getText();

          onScan([scanResult]);
          setDataScan(result);
        }
      },
    );
  };

  useEffect(() => {
    decodeContinuously(selectedDeviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDeviceId]);

  return (
    <div className={styles['sdk-wrapper']} id="sdk-wrapper-scan">
      <img
        alt="screenshot scanned"
        className={styles.image}
        id="pathBg"
        src=""
      />

      {isMakeScreenshot && <LoaderComponent />}

      <div className={styles['sdk-container']}>
        <video className={styles['scandit-video']} id="video" />
      </div>
    </div>
  );
};

export default ZxingScan;
