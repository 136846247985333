import React from 'react';

import styles from './item-result-info.module.scss';

const LogoComponent = function LogoComponent({
  altImage,
  source,
}: any): React.ReactElement {
  if (!source) {
    return <div />;
  }

  return (
    <div className={styles['a-item-info__image']}>
      <img alt={altImage} src={source} />
    </div>
  );
};

export default LogoComponent;
