/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { Button, Header, Modal, Icon } from 'semantic-ui-react';

import { AllowBlockedComponentProperties } from '../../interfaces/interfaces-codes-data';

import styles from './allow-pop-up-component.module.scss';

const AllowPopUpComponent = function AllowPopUpComponent({
  isBlocked,
  message,
  handleClose,
}: AllowBlockedComponentProperties): React.ReactElement {
  const dimmerBlur: string = 'blurring';
  const allowPopUpTitle: string = 'Pop-ups were blocked on this page';
  const closeText: string = 'Close';
  const modalSize: any = 'small';
  const iconName: any = 'remove';

  return (
    <Modal
      basic
      className={styles['a-modal']}
      dimmer={dimmerBlur}
      onClose={() => !isBlocked}
      open={isBlocked}
      size={modalSize}
    >
      <Header>
        <h2 className={styles['a-allow-title']}>{allowPopUpTitle}</h2>
      </Header>

      <Modal.Content>
        <p className={styles['a-allow-text']}>{message}</p>
      </Modal.Content>

      <Modal.Actions>
        <div className={styles['a-button-wrapper']}>
          <Button
            attached={false}
            basic
            inverted
            onClick={() => handleClose({ isBlocked: false, message: '' })}
          >
            <Icon name={iconName} />

            {closeText}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default AllowPopUpComponent;
