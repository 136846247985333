import React from 'react';
import { useSelector } from 'react-redux';

import {
  ContentLogoProperties,
  ItemNameProperties,
  RequestLogoProperties,
} from '../../interfaces/interfaces-codes-data';
import { OVERLAY_SETTINGS_STYLE } from '../../core/constants/constants';

import styles from './item-result-name.module.scss';

const ItemNameResult = function ItemNameResult({
  scanedCodeEventLogo,
  scanedCodeEventName,
  styleReference,
}: ItemNameProperties): React.ReactElement {
  const altImage: string = 'Event logo';

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const logoContent: any = useSelector(
    (state: { contentLogosState: ContentLogoProperties }) => {
      const cachedElement = state.contentLogosState.content.find(
        (element: RequestLogoProperties) => element.id === scanedCodeEventLogo,
      );

      if (!cachedElement) {
        return false;
      }

      return cachedElement.value;
    },
  );

  return (
    <div
      className={styles['a-item-name']}
      style={{
        backgroundColor: styleReference.overlayTopColor,
        color: styleReference.eventTitleColor,
        fontFamily: styleReference.eventFont.name,

        fontStyle: styleReference.eventFont.isItalic
          ? OVERLAY_SETTINGS_STYLE.FONT_STYLE_ITALIC
          : OVERLAY_SETTINGS_STYLE.FONT_STYLE_NORMAL,

        fontWeight: styleReference.eventFont.isBold
          ? OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_BOLD
          : OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_NORMAL,
      }}
    >
      {scanedCodeEventName}

      {logoContent && (
        <div className={styles['a-wrapper-image']}>
          <img alt={altImage} src={logoContent} />
        </div>
      )}
    </div>
  );
};

export default ItemNameResult;
