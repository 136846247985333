/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { Button, Header, Modal, Icon } from 'semantic-ui-react';

import { ErrorComponentProperties } from '../../interfaces/interfaces-codes-data';

import styles from './error-component.module.scss';

const ErrorComponent = function ErrorComponent({
  isError,
  message,
  handleClose,
}: ErrorComponentProperties): React.ReactElement {
  const dimmerBlur: string = 'blurring';
  const errorTitle: string = 'Error';
  const closeText: string = 'Close';
  const modalSize: any = 'small';
  const colorButton: any = 'red';
  const iconName: any = 'remove';

  return (
    <Modal
      basic
      dimmer={dimmerBlur}
      onClose={() => !isError}
      open={isError}
      size={modalSize}
    >
      <Header>
        <h2 className={styles['a-error-title']}>{errorTitle}</h2>
      </Header>

      <Modal.Content>
        <p className={styles['a-error-text']}>{message}</p>
      </Modal.Content>

      <Modal.Actions>
        <div className={styles['a-button-wrapper']}>
          <Button
            attached={false}
            basic
            color={colorButton}
            inverted
            onClick={() => handleClose({ isError: false, message: '' })}
          >
            <Icon name={iconName} />

            {closeText}
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};

export default ErrorComponent;
