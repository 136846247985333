const urlToBase64 = async (url: string) => {
  const preview = await fetch(url).then((result) => result.blob());
  const reader = new FileReader();

  // eslint-disable-next-line promise/avoid-new
  return new Promise((resolve) => {
    reader.addEventListener('load', () => {
      resolve(reader.result);
    });
    reader.readAsDataURL(preview);
  });
};

const FetcherAndConvertor = {
  single: urlToBase64,

  multiple: (links: any) => {
    return Promise.allSettled(
      links.map((element: any) => {
        return urlToBase64(element);
      }),
    );
  },
};

export default FetcherAndConvertor;
