/* eslint-disable default-param-last,import/prefer-default-export */
import {
  ADD_EVENT_ID,
  ScanditAction,
} from '../actionTypes/scandit-action-types';

const initialState: any = '';

export const addEventIdReducer = (
  state: any = initialState,

  action: ScanditAction,
) => {
  return action.type === ADD_EVENT_ID ? action.id : state;
};
