/* eslint-disable fp/no-let, fp/no-mutation */
const setOverlayStyle = ({
  backgroundHeight,
  backgroundY,
  backgroundX,
  backgroundAlign,
}: any): any => {
  const overlayImageStyles: any = {
    height: `${backgroundHeight}%`,
    top: `${backgroundY}%`,
  };

  let transformX = `${backgroundX}%`;

  if (backgroundAlign === 'left' || backgroundAlign === 'right') {
    overlayImageStyles[backgroundAlign] = `${backgroundX}%`;
    transformX = backgroundAlign === 'left' ? `-${transformX}` : transformX;
  } else if (backgroundAlign === 'center') {
    transformX = '-50%';
    overlayImageStyles.left = '50%';
    overlayImageStyles.right = 'initial';
  }

  overlayImageStyles.transform = `translate(${transformX}, -50%)`;

  return overlayImageStyles;
};

export default setOverlayStyle;
