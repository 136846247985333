/* eslint-disable max-statements,padding-line-between-statements,no-console */
/* eslint-disable func-style,import/exports-last */
import { put, select, call, takeEvery, all, fork } from 'redux-saga/effects';

import {
  AddEventId,
  AddPriorityState,
  AddContentLogoState,
  AddKeyLogoState,
  SetUploadStatusState,
  GetQrCodesFailure,
} from '../actionCreators/codes-qr-action-creators';
import {
  priorityStateSelector,
  eventIdSelector,
  contentLogosStateSelector,
} from '../selectors/selectors';
import {
  GetQrCodesDataAction,
  GET_QR_CODES_DATA,
  LOGO_TO_UPLOAD,
} from '../actionTypes/scandit-action-types';
import filterByEventId from '../services/filterByEventIdService';
import setExpireTimeService from '../services/setExpireTimeService';
import getCodesInfoForStoringDataService from '../services/getCodesInfoForStoringDataService';
import FetcherAndConvertor from '../services/fetcherAndConvertor';
import getInfoCodes from '../services/cacheService';

// eslint-disable-next-line import/group-exports
export function* onLoadCodesData({ codes }: GetQrCodesDataAction) {
  try {
    const fundCodesFromCache = yield call(getInfoCodes, codes);
    if (!fundCodesFromCache) return;
    yield put(AddEventId(fundCodesFromCache[0].event.id));

    const hasEventId: any = yield select(eventIdSelector);
    const dataForFiltering = {
      fundCodesFromCache,
      hasEventId,
    };

    const priorityState: any = yield select(priorityStateSelector);

    if (fundCodesFromCache[0].event.logo) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      yield logoToUpload(fundCodesFromCache[0].event.logo);
    }

    if (
      fundCodesFromCache[0].event.stylesConfiguration.overlayImageBackground
    ) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      yield logoToUpload(
        fundCodesFromCache[0].event.stylesConfiguration.overlayImageBackground,
      );
    }

    const sponsorsUrl: any = fundCodesFromCache[0].event.sponsorsLogo;

    // eslint-disable-next-line fp/no-loops
    for (const url of sponsorsUrl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      yield logoToUpload(url);
    }

    const filteredItemsByEventId = yield call(
      filterByEventId,
      dataForFiltering,
    );

    const codesFromSetExpireTime = yield call(
      setExpireTimeService,
      filteredItemsByEventId,
    );

    const dataForGotCodesInfoForStore = {
      codesFromSetExpireTime,
      allStoredData: priorityState.data,
    };

    const gotCodesInfoForStore = yield call(
      getCodesInfoForStoringDataService,
      dataForGotCodesInfoForStore,
    );

    yield put(AddPriorityState(gotCodesInfoForStore));
  } catch (error) {
    console.log(error);
    yield put(GetQrCodesFailure(error.message));
  }
}

// eslint-disable-next-line import/group-exports,import/no-unused-modules
export function* logoToUpload(Url: any) {
  try {
    const gotKeysFromStore: any = yield select(contentLogosStateSelector);

    const hasKey: any = gotKeysFromStore.content.find(
      (item: any) => item.id === Url,
    );
    const isEmptyContentExists: any = gotKeysFromStore.content.find(
      (element: any) => element.value === '',
    );

    if (!isEmptyContentExists && !gotKeysFromStore.isUploaded) {
      yield put(SetUploadStatusState(true));
    }

    if (hasKey) return;

    yield put(AddKeyLogoState(Url));

    yield put(SetUploadStatusState(false));

    const value = yield call(FetcherAndConvertor.single, Url);

    const addContentValue = { id: Url, value };

    yield put(AddContentLogoState(addContentValue));

    if (!isEmptyContentExists) {
      yield put(SetUploadStatusState(true));
    }
  } catch (error) {
    console.log(error);
    yield put(GetQrCodesFailure(error.message));
  }
}

function* watchOnLoadCodesData() {
  yield takeEvery(GET_QR_CODES_DATA, onLoadCodesData);
  yield takeEvery(LOGO_TO_UPLOAD, logoToUpload);
}

export default function* scanditSaga() {
  yield all([fork(watchOnLoadCodesData)]);
}
