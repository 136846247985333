import React from 'react';

import { EventProperties } from '../../interfaces/interfaces-codes-data';
import ScanedItemResult from '../molecules/scaned-item-result';

import styles from './overlay-result.module.scss';

const Overlay = function ({
  isShow,
  isShowSponsorsLogo,
  gotOverlay,
  currentEventId,
}: EventProperties): React.ReactElement {
  const idSdkOverlay = 'sdk_overlay';

  return (
    <div className={styles['o-overlay-result']} id={idSdkOverlay}>
      {isShow && gotOverlay && gotOverlay.eventId === currentEventId && (
        <ScanedItemResult
          isShowSponsorsLogo={isShowSponsorsLogo}
          item={gotOverlay}
          scanedCodeEventSponsorLogo={gotOverlay.eventSponsorLogo}
          stylesConfiguration={gotOverlay.stylesConfiguration}
        />
      )}
    </div>
  );
};

export default Overlay;
