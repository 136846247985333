import React from 'react';
import { Loader, Dimmer } from 'semantic-ui-react';

const LoaderComponent = function LoaderComponent(): React.ReactElement {
  const textForLoader: string = 'Loading';

  return (
    <Dimmer active>
      <Loader>{textForLoader}</Loader>
    </Dimmer>
  );
};

export default LoaderComponent;
