/* eslint-disable fp/no-mutation */
import { RequestItemProperties } from '../../interfaces/interfaces-codes-data';

const getCodesInfoForStoringDataService = (
  dataForGotCodesInfoForStore: any,
) => {
  const incomingData = dataForGotCodesInfoForStore.codesFromSetExpireTime;
  const storedData = dataForGotCodesInfoForStore.allStoredData;

  storedData.forEach((element: RequestItemProperties, currentIndex: number) => {
    incomingData.forEach((incoming: RequestItemProperties) => {
      if (element.id === incoming.id) {
        storedData[currentIndex] = { ...incoming, setup: element.setup };
      }
    });
  });

  const notExistsCodes = incomingData.filter(
    (element: RequestItemProperties) =>
      !storedData.some(
        (saved: RequestItemProperties) => saved.id === element.id,
      ),
  );

  const withSetupDate = notExistsCodes.map((code: any) => ({
    ...code,
    setup: Date.now(),
  }));

  return storedData
    .filter(
      (code: RequestItemProperties) =>
        code.timestamp && code.timestamp > Date.now(),
    )
    .concat(withSetupDate);
};

export default getCodesInfoForStoringDataService;
