import axios from 'axios';

import { PostsEntity } from '../../interfaces/interfaces-codes-data';

const createPostService = async (
  blobFile: any,
  imgElement: any,
  postBody: PostsEntity,
) => {
  try {
    const { s3url, id } = (
      await axios.get<{ s3url: string; id: string }>(
        `${process.env.REACT_APP_URL_API}api/media/get-signed-url?bucket-prefix=posts`,
      )
    ).data;

    const file = new File([blobFile], `${id}.jpeg`);

    imgElement?.setAttribute('style', 'opacity: 0;');

    await axios.put(s3url, file, {
      headers: {
        'Content-Type': 'image/jpeg',
      },
    });

    const postEntries: any = {
      itemIds: postBody.id,
      eventId: postBody.eventId,
      imageId: `/posts/${id}.jpeg`,
      eventTitle: postBody.eventTitle,
      ownerTwitterIds: postBody.ownerTwitterIds,
      eventLinks: postBody.eventLinks,
      ownerCurrentFundMeLink: postBody.ownerCurrentFundMeLink,
      description: postBody.description,
    };

    return await axios.post(
      `${process.env.REACT_APP_URL_API}api/posts`,
      postEntries,
      { headers: { 'Content-Type': 'application/json' } },
    );
  } catch {
    // eslint-disable-next-line fp/no-throw
    throw new Error('An issue occurred while uploading the post image');
  }
};

export default createPostService;
