/* eslint-disable import/group-exports,func-style,import/no-unused-modules */
import {
  GetQrCodesDataAction,
  AddEventIdAction,
  ADD_EVENT_ID,
  GET_QR_CODES_DATA,
  GET_QR_CODES_FAILURE,
  ADD_PRIORITY_STATE,
  LOGO_TO_UPLOAD,
  ShowHideSponsorsLogoAction,
  SetUploadStatusStateAction,
  SHOW_SPONSORS_LOGO,
  ADD_CONTENT_LOGO_STATE,
  ADD_KEY_LOGO_STATE,
  SET_UPLOAD_STATUS_STATE,
} from '../actionTypes/scandit-action-types';

export function GetQrCodesData(codes: any): GetQrCodesDataAction {
  return {
    type: GET_QR_CODES_DATA,
    codes,
  };
}

export function AddEventId(id: string): AddEventIdAction {
  return {
    type: ADD_EVENT_ID,
    id,
  };
}

export function ShowHideSponsorsLogo(
  isShow: boolean,
): ShowHideSponsorsLogoAction {
  return {
    type: SHOW_SPONSORS_LOGO,
    isShow,
  };
}

export function AddPriorityState(data: any) {
  return {
    type: ADD_PRIORITY_STATE,
    data,
  };
}

export function AddContentLogoState(value: any) {
  return {
    type: ADD_CONTENT_LOGO_STATE,
    value,
  };
}

export function AddKeyLogoState(id: any) {
  return {
    type: ADD_KEY_LOGO_STATE,
    id,
  };
}

// eslint-disable-next-line no-shadow
export function SetUploadStatusState(status: any): SetUploadStatusStateAction {
  return {
    type: SET_UPLOAD_STATUS_STATE,
    status,
  };
}

export function CatchContentLogoState(content: any, logosLength: any) {
  return {
    type: LOGO_TO_UPLOAD,
    content,
    logosLength,
  };
}

export function GetQrCodesFailure(
  error: string | Error,
): { type: string; error: Error | string } {
  return {
    type: GET_QR_CODES_FAILURE,
    error,
  };
}
