/* eslint-disable fp/no-mutation,consistent-return */
const createScreenshotFromVideo = (data: any) => {
  const videoBlock = document.querySelector<any>('video');

  const canvas = document.createElement('canvas');

  if (canvas) {
    canvas.width = data.imageSettings.width;
    canvas.height = data.imageSettings.height;

    // @ts-ignore
    canvas
      .getContext('2d')
      .drawImage(videoBlock, 0, 0, canvas.width, canvas.height);

    return canvas.toDataURL('image /png');
  }
};

export default createScreenshotFromVideo;
