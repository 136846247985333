/* eslint-disable import/max-dependencies,max-statements,sonarjs/cognitive-complexity,react/jsx-no-literals,no-warning-comments */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import currentOrientation from '../../core/services/currentImageSettings';
import BarcodePicker from '../organisms/barcode-picker';
import { SCAN_SETTINGS, SCAN_TYPE } from '../../core/constants/constants';
import ZxingScan from '../organisms/zxing-scanner';
import createScreenshotFromVideo from '../../core/services/createScreenshotFromVideoData';
import createScreenshotFromHtml from '../../core/services/createScreenshotFromHtml';
import createPostService from '../../core/services/createPostService';
import simulationClickService from '../../core/services/simulationClickService';
import { ShowHideSponsorsLogo } from '../../core/actionCreators/codes-qr-action-creators';
import {
  contentLogosStateSelector,
  isShowSponsorsLogoSelector,
} from '../../core/selectors/selectors';
import {
  AllowBlockedPopUpProperties,
  ErrorProperties,
} from '../../interfaces/interfaces-codes-data';
import ErrorComponent from '../atoms/error-component';
import AllowPopUpComponent from '../atoms/allow-pop-up-component';
import LoaderComponent from '../atoms/loader-component';
import styles from '../organisms/barcode-picker.module.scss';

const ChangeCurrentScanner = function ChangeCurrentScanner({
  contentLogos,
  handleOnScan,
  filteredDataOverlay,
  visible,
}: any) {
  const [isShowBtns, setIsShowBtns] = useState<boolean>(true);
  // TODO: checking blocked window, need to finish
  // const [isOpenedWindow, setIsOpenedWindow] = useState<boolean>(true);
  const [isMakeScreenshot, setIsMakeScreenshot] = useState<boolean>(false);
  const [currentScanner, setCurrentScanner] = useState(SCAN_TYPE.FREE);
  const [dataScan, setDataScan] = useState<any>();

  const setScanData = () => {
    const videoBlock = document.querySelector<any>('#video');

    if (currentScanner !== SCAN_TYPE.PREMIUM && dataScan) {
      return {
        imageData: dataScan.rawBytes,

        imageSettings: {
          width: videoBlock.offsetWidth,
          height: videoBlock.offsetHeight,
        },
      };
    }

    return dataScan;
  };

  const [
    imageSettingsFromOrientation,
    setImageSettingsFromOrientation,
  ] = useState<any>(currentOrientation());

  const [
    blockedWindowHandling,
    setBlockedWindowHandling,
  ] = useState<AllowBlockedPopUpProperties>({
    isBlocked: false,
    message: '',
  });
  const [errorHandling, setErrorHandling] = useState<ErrorProperties>({
    isError: false,
    message: '',
  });

  const dispatch = useDispatch();
  const isShowSponsorLogo = useSelector(isShowSponsorsLogoSelector);

  const makeScreenshot = async (data: any) => {
    if (data === undefined) return;

    setIsShowBtns(false);

    const cameraSwitcher = document.querySelector('.scandit-camera-switcher');

    cameraSwitcher?.setAttribute('style', 'opacity: 0;');

    // get screenshot
    const backgroundBase64 = await createScreenshotFromVideo(data);

    if (backgroundBase64) {
      const img = document.querySelector('#pathBg');

      img?.setAttribute('style', 'opacity: 1;');
      img?.setAttribute('src', backgroundBase64);

      try {
        const url: any = await createScreenshotFromHtml(
          imageSettingsFromOrientation.gotWidth,
          imageSettingsFromOrientation.gotHeight,
          imageSettingsFromOrientation.gotX,
          imageSettingsFromOrientation.gotY,
        );

        if (!url) return;

        cameraSwitcher?.setAttribute('style', 'opacity: 1;');
        img?.setAttribute('src', url);

        const blob = await (await fetch(url)).blob();

        setIsMakeScreenshot(true);

        const savedPostData = await createPostService(
          blob,
          img,
          filteredDataOverlay,
        );

        if (!savedPostData) return;

        const postedResponse = {
          alternativeId: savedPostData.data.data.alternativeId,
          imageId: savedPostData.data.data.imageId,
          ownerTwitterIds: savedPostData.data.data.ownerTwitterIds,
          eventLinks: savedPostData.data.data.eventLinks,
          eventTitle: savedPostData.data.data.eventTitle,

          ownerCurrentFundMeLink:
            savedPostData.data.data.ownerCurrentFundMeLink,

          description: savedPostData.data.data.description,
        };

        simulationClickService({
          ...postedResponse,
          organisationTwitterId: filteredDataOverlay.organisationTwitterId,
          eventSponsorsInfo: filteredDataOverlay.eventSponsorInfo,
        });
        setIsMakeScreenshot(false);
        setIsShowBtns(true);
        dispatch(ShowHideSponsorsLogo(false));
      } catch (error) {
        setIsMakeScreenshot(false);
        setErrorHandling({
          isError: true,
          message: error.message,
        });
      }
    }
  };

  const cachedLogos: any = useSelector(contentLogosStateSelector);

  const isButtonDisabled = () => {
    // eslint-disable-next-line unicorn/explicit-length-check
    if (!cachedLogos.content.length) {
      return false;
    }

    return !cachedLogos.isUploaded;
  };

  const handleClick = () => {
    dispatch(ShowHideSponsorsLogo(true));
  };

  // TODO: checking blocked window, need to finish
  // const handleShowBlockedPopup = () => {
  //   const openedWindow: any = window.open();
  //
  //   setIsOpenedWindow(false);
  //
  //   setTimeout(() => {
  //     if (openedWindow === null || !openedWindow) {
  //       setBlockedWindowHandling({
  //         isBlocked: true,
  //         message: WINDOW.MESSAGE,
  //       });
  //     } else {
  //       openedWindow.close();
  //       setBlockedWindowHandling({
  //         isBlocked: false,
  //         message: '',
  //       });
  //     }
  //   }, ALLOW_POP_UP_CHECK_NUMBER.TIME_OUT);
  // };
  //
  // useEffect(() => {
  //   if (filteredDataOverlay !== undefined && isOpenedWindow) {
  //     handleShowBlockedPopup();
  //   }
  // }, [filteredDataOverlay, isOpenedWindow]);

  useEffect(() => {
    if (filteredDataOverlay?.isPremium) {
      setCurrentScanner(SCAN_TYPE.PREMIUM);
    }
  }, [setCurrentScanner, filteredDataOverlay]);

  useEffect(() => {
    setImageSettingsFromOrientation(currentOrientation());

    window.addEventListener(
      'resize',
      () => {
        window.location.reload(false);
        setImageSettingsFromOrientation(currentOrientation());
      },
      false,
    );
  }, [setImageSettingsFromOrientation]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isShowSponsorLogo) {
      return () => {};
    }

    makeScreenshot(setScanData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowSponsorLogo]);

  return (
    <>
      {isShowBtns && filteredDataOverlay !== undefined && (
        <div className={styles['button-wrapper']} id="button-wrapper">
          <button
            className={styles['button-screenshot']}
            data-testid="screenshot"
            disabled={isButtonDisabled()}
            onClick={handleClick}
            type="button"
          >
            {isButtonDisabled() ? (
              <Loader active inline="centered" size="mini" />
            ) : (
              'Post Picture'
            )}
          </button>
        </div>
      )}

      {blockedWindowHandling.isBlocked && (
        <AllowPopUpComponent
          handleClose={setBlockedWindowHandling}
          isBlocked={blockedWindowHandling.isBlocked}
          message={blockedWindowHandling.message}
        />
      )}

      {errorHandling.isError && (
        <ErrorComponent
          handleClose={setErrorHandling}
          isError={errorHandling.isError}
          message={errorHandling.message}
        />
      )}

      {isMakeScreenshot && <LoaderComponent />}

      {currentScanner === SCAN_TYPE.PREMIUM ? (
        <BarcodePicker
          contentSponsorsLogo={contentLogos}
          isLaserArea={SCAN_SETTINGS.LASER_AREA}
          isMirrorImageEnabled={false}
          makeScreenshot={makeScreenshot}
          onScan={(data: any) => handleOnScan(data)}
          setDataScan={setDataScan}
          visible={visible}
        />
      ) : (
        <ZxingScan
          onScan={(data: any) => handleOnScan(data)}
          scannerType={currentScanner}
          setDataScan={setDataScan}
        />
      )}
    </>
  );
};

export default ChangeCurrentScanner;
