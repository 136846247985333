/* eslint-disable fp/no-mutating-methods,fp/no-mutation,max-statements */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Overlay from '../organisms/overlay-result';
import {
  OVERLAY_SETTINGS,
  BARCODE_SETTINGS,
} from '../../core/constants/constants';
import {
  eventIdSelector,
  filterDataSelector,
  isShowSponsorsLogoSelector,
  filterContentLogoSelector,
} from '../../core/selectors/selectors';
import { RequestItemProperties } from '../../interfaces/interfaces-codes-data';
import { GetQrCodesData } from '../../core/actionCreators/codes-qr-action-creators';
import OverlayPremium from '../organisms/overlay-result-premium';
import getInfoCodes from '../../core/services/cacheService';

import ChangeCurrentScanner from './change-current-scanner';
// eslint-disable-next-line import/max-dependencies
import styles from './one-col.module.scss';

const getCurrentIdFromBarcode = (element: any) => {
  const linkToArray = element.split('/');

  return linkToArray[linkToArray.length - 1];
};

interface ParameterTypes {
  id: string;
}

const MainLayout = function MainLayout(): React.ReactElement {
  const [codesArray, setCodesArray] = useState<RequestItemProperties[] | []>(
    [],
  );
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<any>(false);

  const dispatch = useDispatch();
  const { id } = useParams<ParameterTypes>();
  const filteredDataOverlay: any = useSelector(filterDataSelector);
  const isShowSponsorsLogo: any = useSelector(isShowSponsorsLogoSelector);
  const contentLogos: any = useSelector(filterContentLogoSelector);
  const currentEventId: any = useSelector(eventIdSelector);
  const hasIdInHash: boolean = id !== undefined;

  const timeoutIdReference = React.useRef(timeoutId);
  const timeoutIsFirst = React.useRef(true);

  const setTimeoutIdWithReference = (data: any) => {
    timeoutIdReference.current = data;
    setTimeoutId(data);
  };

  const codesArrayReference = React.useRef(codesArray);
  const currentEvent: any = React.useRef(currentEventId);

  currentEvent.current = currentEventId;

  const codesArrayWithReference = (data: any) => {
    codesArrayReference.current = data;
    setCodesArray(data);
  };

  const setOverlayOpen = (timeoutReferenceId: any) => {
    const currentTimeoutBetweenScan = timeoutIsFirst.current
      ? OVERLAY_SETTINGS.AFTER_FIRST_SCAN_TIMEOUT
      : OVERLAY_SETTINGS.AFTER_EACH_SCAN_TIMEOUT;

    setShowOverlay(true);

    if (timeoutReferenceId) {
      clearTimeout(timeoutReferenceId);
    }

    const TimeoutId = setTimeout(() => {
      setShowOverlay(false);
      timeoutIsFirst.current = false;
    }, currentTimeoutBetweenScan);

    setTimeoutIdWithReference(TimeoutId);
  };

  const handleResetCodes = () => {
    codesArrayWithReference([]);
  };

  const handleOnScan = (codes: any) => {
    const currentIds: any = [];

    codes.forEach((element: any) => {
      currentIds.push(getCurrentIdFromBarcode(element));
    });

    dispatch(GetQrCodesData(currentIds));

    setOverlayOpen(timeoutIdReference.current);
  };

  const isPremiumOverlay =
    filteredDataOverlay?.isPremium || filteredDataOverlay?.isFreePremiumOverlay;

  useEffect((): any => {
    getInfoCodes([id]);

    if (hasIdInHash) {
      setTimeout(() => {
        dispatch(GetQrCodesData([id]));
        setShowOverlay(true);
      }, OVERLAY_SETTINGS.HIDE_TIMEOUT_FIRST_SCAN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, setShowOverlay]);

  useEffect((): any => {
    if (hasIdInHash) {
      setTimeout(() => {
        setShowOverlay(false);
      }, OVERLAY_SETTINGS.AFTER_FIRST_SCAN_FROM_HASH_TIMEOUT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setShowOverlay]);

  return (
    <div
      className={styles['t-wrapper']}
      id={BARCODE_SETTINGS.SDC_WRAPPER_CLASSNAME}
    >
      <ChangeCurrentScanner
        contentLogos={contentLogos}
        filteredDataOverlay={filteredDataOverlay}
        handleOnScan={handleOnScan}
        handleResetCodes={handleResetCodes}
        handleShowOverlay={setShowOverlay}
        visible
      />

      <div className={styles['t-wrapper__screenshot-space']}>
        {isPremiumOverlay ? (
          <OverlayPremium
            contentLogos={filteredDataOverlay}
            currentEventId={currentEvent.current}
            gotOverlay={filteredDataOverlay}
            isShow={showOverlay}
            isShowSponsorsLogo={isShowSponsorsLogo}
          />
        ) : (
          <Overlay
            contentLogos={filteredDataOverlay}
            currentEventId={currentEvent.current}
            gotOverlay={filteredDataOverlay}
            isShow={showOverlay}
            isShowSponsorsLogo={isShowSponsorsLogo}
          />
        )}
      </div>
    </div>
  );
};

export default MainLayout;
