/* eslint-disable no-restricted-globals */
import { ORIENTATION_SETTINGS } from '../constants/constants';

const getOrientation: any = () => window.innerHeight < window.innerWidth;

const currentOrientation = () => {
  const getMobileWidth: any = () =>
    getOrientation() ? screen.availHeight : screen.availWidth;

  const getMobileHeight: any = () =>
    getOrientation() ? screen.availWidth : screen.availHeight;
  const getWidth: any = () =>
    getOrientation() ? window.innerWidth : ORIENTATION_SETTINGS.STATIC_WIDTH;

  const getHeight: any = () =>
    getOrientation() ? window.innerHeight : ORIENTATION_SETTINGS.STATIC_HEIGHT;

  const getX: any = () => {
    if (getOrientation()) {
      return 0;
    }

    return `-${(getWidth() - getMobileWidth()) / ORIENTATION_SETTINGS.PARTIES}`;
  };

  const getY: any = () => {
    if (getOrientation()) {
      return 0;
    }

    return (getHeight() - getMobileHeight()) / ORIENTATION_SETTINGS.PARTIES;
  };

  return {
    isLandscape: getOrientation(),
    gotWidth: getWidth(),
    gotHeight: getHeight(),
    gotX: getX(),
    gotY: getY(),
  };
};

export default currentOrientation;
