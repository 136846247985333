/* eslint-disable default-param-last,import/prefer-default-export */
import {
  ScanditAction,
  SHOW_SPONSORS_LOGO,
} from '../actionTypes/scandit-action-types';

const initialState: any = false;

export const showSponsorsLogoReducer = (
  state: any = initialState,

  action: ScanditAction,
) => {
  return action.type === SHOW_SPONSORS_LOGO ? action.isShow : state;
};
