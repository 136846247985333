/* eslint-disable import/prefer-default-export */
import { RequestLogoProperties } from '../../interfaces/interfaces-codes-data';

export const getImageHash = (
  url: string,
  contentLogosState: { content: any[] },
) => {
  const cachedElement = contentLogosState.content.find(
    (element: RequestLogoProperties) => element.id === url,
  );

  if (!cachedElement) {
    return false;
  }

  return cachedElement.value;
};
