/* eslint-disable react/jsx-no-literals,react/no-children-prop,import/no-unassigned-import */
import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Container from './components/pages/scandit-page';

import 'semantic-ui-css/semantic.min.css';

const ScannerApp = function ScannerApp() {
  return (
    <Switch>
      <Route children={<Container />} exact path="/" />

      <Route children={<Container />} path="/:id" />
    </Switch>
  );
};

export default ScannerApp;
