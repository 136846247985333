import React from 'react';

import { ItemsOverlayProperties } from '../../interfaces/interfaces-codes-data';
import ItemNameResult from '../atoms/item-result-name';
import ItemResultInfo from '../atoms/item-result-info';

import styles from './scaned-item-result.module.scss';

const ScanedItemResult = function ScanedItemResult({
  item,
  isShowSponsorsLogo,
  stylesConfiguration,
}: ItemsOverlayProperties): React.ReactElement {
  return (
    <div className={styles['m-item-result']}>
      <ItemNameResult
        scanedCodeEventLogo={item.eventLogo}
        scanedCodeEventName={item.eventTitle}
        styleReference={stylesConfiguration}
      />

      <ItemResultInfo
        isShowSponsorsLogo={isShowSponsorsLogo}
        scanedCodeDescription={item.description}
        scanedCodeEventSponsorLogo={item.eventSponsorLogo}
        scanedCodeName={item.name}
        styleReference={stylesConfiguration}
      />
    </div>
  );
};

export default ScanedItemResult;
