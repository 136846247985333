import html2canvas from 'html2canvas';

const createScreenshotFromHtml = async (
  width: number,
  height: number,
  x: number,
  y: number,
  // eslint-disable-next-line max-params
) => {
  const parentElement = document.querySelector<any>('#sdk-wrappers');

  if (!parentElement) return;

  const canvas2 = await html2canvas(parentElement, {
    width,
    height,
    x,
    y,
    backgroundColor: 'transparent',
  });

  // eslint-disable-next-line no-magic-numbers,consistent-return
  return canvas2.toDataURL('image/jpeg', 0.6);
};

export default createScreenshotFromHtml;
