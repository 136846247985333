/* eslint-disable react/jsx-max-depth */
import React from 'react';
import { useSelector } from 'react-redux';

import { ItemsOverlayProperties } from '../../interfaces/interfaces-codes-data';
import ItemResultInfoPremium from '../atoms/item-result-info-premium';
import { OVERLAY_SETTINGS_STYLE } from '../../core/constants/constants';
import { getImageHash } from '../../core/services/selectContent';
import { logoContentSelector } from '../../core/selectors/selectors';
import setOverlayStyle from '../../core/services/overlayBackground';

import styles from './scaned-item-result-premium.module.scss';

const ScanedItemResultPremium = function ScanedItemResultPremium({
  item,
  isShowSponsorsLogo,
  stylesConfiguration,
}: ItemsOverlayProperties): React.ReactElement {
  const altImage: string = 'Event logo';
  const contentLogosState = useSelector(logoContentSelector);
  const content: string | undefined = getImageHash(
    item.eventLogo,
    contentLogosState,
  );

  const background: string | undefined = getImageHash(
    stylesConfiguration.overlayImageBackground,
    contentLogosState,
  );

  return (
    <div
      className={styles['m-item-result-premium']}
      style={{
        backgroundColor: stylesConfiguration.overlayBackground,
        width: '100%',
        height: '100%',
      }}
    >
      <div className={styles['m-item-result-premium__wrapper']}>
        <div className={styles['m-item-result-premium__logo-container']}>
          {content && (
            <div className={styles['m-item-result-premium__wrapper-image']}>
              <img alt={altImage} src={content} />
            </div>
          )}
        </div>

        <div
          className={styles['m-item-result-premium__info-container']}
          style={{
            color: stylesConfiguration.eventTitleColor,
            fontFamily: stylesConfiguration.eventFont.name,
            fontSize: `${stylesConfiguration.eventFont.fontSize}px`,

            fontStyle: stylesConfiguration.eventFont.isItalic
              ? OVERLAY_SETTINGS_STYLE.FONT_STYLE_ITALIC
              : OVERLAY_SETTINGS_STYLE.FONT_STYLE_NORMAL,

            fontWeight: stylesConfiguration.eventFont.isBold
              ? OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_BOLD
              : OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_NORMAL,
          }}
        >
          {item.name}

          <ItemResultInfoPremium
            isShowSponsorsLogo={isShowSponsorsLogo}
            scanedCodeDescription={item.description}
            scanedCodeEventName={item.eventTitle}
            scanedCodeEventSponsorLogo={item.eventSponsorLogo}
            styleReference={stylesConfiguration}
          />
        </div>
      </div>

      <div
        className={styles['m-item-result-premium__dynamic-background-image']}
      >
        <div
          className={
            styles[
              'm-item-result-premium__dynamic-background-image__wrapper-image'
            ]
          }
        >
          {background ? (
            <img
              alt={altImage}
              src={background}
              style={setOverlayStyle(item.stylesConfiguration.background)}
            />
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default ScanedItemResultPremium;
