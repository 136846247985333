import { Barcode, ScanSettings } from 'scandit-sdk';

import { SCAN_SETTINGS } from '../constants/constants';

const getScanSettings = () => {
  return new ScanSettings({
    enabledSymbologies: [Barcode.Symbology.QR],
    codeDuplicateFilter: SCAN_SETTINGS.DUPLICATE_FILTER,
    maxNumberOfCodesPerFrame: SCAN_SETTINGS.CODES_LIMIT,
    searchArea: SCAN_SETTINGS.SEARCH_AREA,
  });
};

export default getScanSettings;
