/* eslint-disable import/group-exports,import/no-unused-modules */
import { RequestItemProperties } from '../../interfaces/interfaces-codes-data';

export const GET_QR_CODES_DATA = 'scanditActionTypes/GET_QR_CODES_DATA';
export const ADD_EVENT_ID = 'scanditActionTypes/ADD_EVENT_ID';
export const SHOW_SPONSORS_LOGO = 'scanditActionTypes/SHOW_SPONSORS_LOGO';
export const ADD_PRIORITY_STATE = 'scanditActionTypes/ADD_PRIORITY_STATE';
export const LOGO_TO_UPLOAD = 'scanditActionTypes/LOGO_TO_UPLOAD';
export const ADD_KEY_LOGO_STATE = 'scanditActionTypes/ADD_KEY_LOGO_STATE';
export const SET_UPLOAD_STATUS_STATE =
  'scanditActionTypes/SET_UPLOAD_STATUS_STATE';
export const ADD_CONTENT_LOGO_STATE =
  'scanditActionTypes/ADD_CONTENT_LOGO_STATE';
export const GET_QR_CODES_FAILURE = 'scanditActionTypes/GET_QR_CODES_FAILURE';

export interface GetQrCodesDataAction {
  type: typeof GET_QR_CODES_DATA;
  codes: string[];
}

export interface AddEventIdAction {
  type: typeof ADD_EVENT_ID;
  id: string;
}

export interface ShowHideSponsorsLogoAction {
  type: typeof SHOW_SPONSORS_LOGO;
  isShow: boolean;
}

export interface AddPriorityStateAction {
  type: typeof ADD_PRIORITY_STATE;
  data: RequestItemProperties[];
}

export interface AddContentLogoStateAction {
  type: typeof ADD_CONTENT_LOGO_STATE;
  value: any;
}

export interface AddKeyLogoStateAction {
  type: typeof ADD_KEY_LOGO_STATE;
  id: string;
}

export interface SetUploadStatusStateAction {
  type: typeof SET_UPLOAD_STATUS_STATE;
  status: boolean;
}

export interface CatchContentLogoAction {
  type: typeof LOGO_TO_UPLOAD;
  content: any;
}

export interface GetQrCodesDataFailureAction {
  type: typeof GET_QR_CODES_FAILURE;
}

export type ScanditAction =
  | GetQrCodesDataAction
  | AddPriorityStateAction
  | AddContentLogoStateAction
  | AddKeyLogoStateAction
  | SetUploadStatusStateAction
  | CatchContentLogoAction
  | AddEventIdAction
  | ShowHideSponsorsLogoAction
  | GetQrCodesDataFailureAction;
