/* eslint-disable import/no-unused-modules */
/* eslint-disable import/group-exports */
const URL_LINE_SEPARATOR = '%0A%20';

export const ALLOW_POP_UP_CHECK_NUMBER = {
  TIME_OUT: 500,
};

export const WINDOW = {
  FEATURES:
    'directories=no,height=100,width=100,menubar=no,resizable=no,scrollbars=no,status=no,titlebar=no,top=0,location=no',

  URL: 'about:blank',
  TARGET: '_blank',
  MESSAGE: 'Please allow the pop-up windows',
};

export const SCAN_SETTINGS: any = {
  DUPLICATE_FILTER: 0,
  CODES_LIMIT: 3,
  SEARCH_AREA: { width: 1, height: 0.75, x: 0, y: 0.25 },
  LASER_AREA: 'NONE',
};
export const SCAN_TYPE = {
  FREE: 'free',
  PREMIUM: 'premium',
};
export const OVERLAY_SETTINGS = {
  AFTER_FIRST_SCAN_FROM_HASH_TIMEOUT: 7000,
  AFTER_FIRST_SCAN_TIMEOUT: 5000,
  AFTER_EACH_SCAN_TIMEOUT: 3000,
  HIDE_TIMEOUT_FIRST_SCAN: 2000,
};
export const OVERLAY_SETTINGS_STYLE = {
  FONT_WEIGHT_NORMAL: 400,
  FONT_WEIGHT_BOLD: 700,
  FONT_STYLE_NORMAL: 'normal',
  FONT_STYLE_ITALIC: 'italic',
};
export const BARCODE_SETTINGS = {
  SDC_WRAPPER_CLASSNAME: 'sdk-wrappers',
  TIME_LIMIT: 2000,
  UPLOADED: 100,
};
export const ORIENTATION_SETTINGS = {
  PARTIES: 2,
  STATIC_WIDTH: 1024,
  STATIC_HEIGHT: 768,
};
export const TWITTER_LINK = `https://twitter.com/intent/tweet?text={OWNER_TWITTER_IDS}${URL_LINE_SEPARATOR}{APP_URL}posts/{REFERENCE_ID}${URL_LINE_SEPARATOR}{EVENT_LINKS}, Organisation twitter: {ORG_TWITTER_ID}, Sponsors: {EVENT_SPONSORS_TWITTER_IDS}`;
