import { TWITTER_LINK } from '../constants/constants';

const createLink = (reference: any) => {
  const REFERENCE_ID = reference.alternativeId;
  const OWNER_TWITTER_IDS = reference.ownerTwitterIds.join(' ');
  const EVENT_LINKS = reference.eventLinks.join(' ');
  const ORG_TWITTER_ID = reference.organisationTwitterId;
  const EVENT_SPONSORS_TWITTER_IDS = reference.eventSponsorsInfo
    .map((info: { twitterId: string; sponsorLink: string }) =>
      info.twitterId === '' ? info.sponsorLink : info.twitterId,
    )
    .join(' ');

  // @ts-ignore
  return TWITTER_LINK.replace('{APP_URL}', process.env.REACT_APP_URL_API)
    .replace('{REFERENCE_ID}', REFERENCE_ID)
    .replace('{OWNER_TWITTER_IDS}', OWNER_TWITTER_IDS)
    .replace('{EVENT_LINKS}', EVENT_LINKS)
    .replace('{ORG_TWITTER_ID}', ORG_TWITTER_ID)
    .replace('{EVENT_SPONSORS_TWITTER_IDS}', EVENT_SPONSORS_TWITTER_IDS);
};
const simulationClickService = (twitterReference: any) => {
  const a = document.createElement('a');

  a.setAttribute('href', createLink(twitterReference));
  a.setAttribute('target', '_blank');
  a.dispatchEvent(
    new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    }),
  );
};

export default simulationClickService;
