/* eslint-disable import/group-exports,no-shadow,consistent-return */
import { AppState } from '../reducers/root-reducer';
import { ContentLogoProperties } from '../../interfaces/interfaces-codes-data';

export const eventIdSelector = (state: AppState) => state.eventId;

export const isShowSponsorsLogoSelector = (state: AppState) =>
  state.showSponsorsLogo;

export const priorityStateSelector = (state: AppState) => state.priorityState;
export const contentLogosStateSelector = (state: AppState) =>
  state.contentLogosState;

export const filterDataSelector = (state: AppState) => {
  const eventId: any = eventIdSelector(state);
  const priorityState: any = priorityStateSelector(state);

  const toBottom = -1;
  const toTop = 1;

  // eslint-disable-next-line fp/no-mutating-methods
  const sortedItems = priorityState.data.sort((a: any, b: any) => {
    if (a.setup > b.setup) {
      return toTop;
    }

    return toBottom;
  });

  if (sortedItems.length === 0) return;

  const names = sortedItems.map((element: any) => element.owner.name);

  const maxUserNames = 3;
  const name =
    names.length < maxUserNames
      ? names.join(' & ')
      : `${names[0]} with ${names.slice(1).join(' & ')}`;

  return {
    id: sortedItems.map((element: any) => element.id),
    name,
    ownerTwitterIds: sortedItems.map((element: any) => element.owner.twitterId),
    eventTitle: sortedItems[0].event.name,
    eventLinks: sortedItems.map((element: any) => element.event.link),
    eventLogo: sortedItems[0].event.logo,
    eventSponsorLogo: sortedItems[0].event.sponsorsLogo,
    eventSponsorInfo: sortedItems[0].event.sponsorsInfo,
    isPremium: sortedItems[0].event.isPremium,
    isFreePremiumOverlay: sortedItems[0].event.isFreePremiumOverlay,

    ownerCurrentFundMeLink: sortedItems.map(
      (element: any) => element.owner.currentFundMeLink,
    ),

    organisationTwitterId: sortedItems[0].organisation.twitterId,
    eventId,
    description: sortedItems[0].owner.statement,
    stylesConfiguration: sortedItems[0].event.stylesConfiguration,
  };
};

export const logoContentSelector = (state: {
  contentLogosState: ContentLogoProperties;
}) => state.contentLogosState;

export const filterContentLogoSelector = (state: AppState) => {
  const filteredDataOverlay: any = filterDataSelector(state);
  const getAllContentLogo: any = contentLogosStateSelector(state);

  const eventLogo = getAllContentLogo.content.find(
    (element: any) => element.id === filteredDataOverlay?.eventLogo,
  );

  const sponsorsLogo = eventLogo
    ? getAllContentLogo.content.filter((item: any) => item.id !== eventLogo?.id)
    : getAllContentLogo.content;

  if (getAllContentLogo.content.length > 0) {
    return {
      eventLogo: eventLogo?.value,
      sponsorsLogo,
      isUploaded: getAllContentLogo.isUploaded,
    };
  }
};
