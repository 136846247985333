import { combineReducers } from 'redux';

import { priorityStateReducer } from './priority-state-reducer';
import { addEventIdReducer } from './add-event-id-reducer';
import { showSponsorsLogoReducer } from './show-sponsors-logo-reducer';
import { contentLogosStateReducer } from './content-logos-reducer';

export const rootReducer = combineReducers({
  priorityState: priorityStateReducer,
  eventId: addEventIdReducer,
  showSponsorsLogo: showSponsorsLogoReducer,
  contentLogosState: contentLogosStateReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
