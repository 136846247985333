import axios from 'axios';
import qs from 'qs';

const fetchData = {
  async execute(codes: any) {
    const fetchedData: any = await axios.get(
      `${process.env.REACT_APP_URL_API}api/scandata`,
      {
        params: {
          codes,
        },

        paramsSerializer(parameters) {
          return qs.stringify(parameters, { arrayFormat: 'repeat' });
        },
      },
    );

    return fetchedData.data.data;
  },
};

export default fetchData;
