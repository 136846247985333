import { OVERLAY_SETTINGS } from '../constants/constants';

const setExpireTimeService = (codesFromCache: any) => {
  return codesFromCache.map((item: any) => {
    return {
      ...item,
      timestamp: Date.now() + OVERLAY_SETTINGS.HIDE_TIMEOUT_FIRST_SCAN,
    };
  });
};

export default setExpireTimeService;
