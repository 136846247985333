/* eslint-disable import/prefer-default-export,default-param-last */
import { QRDataState } from '../../interfaces/interfaces-codes-data';
import {
  ScanditAction,
  ADD_PRIORITY_STATE,
} from '../actionTypes/scandit-action-types';

const initialState: any = {
  data: [],
};

export const priorityStateReducer = (
  state: QRDataState = initialState,

  action: ScanditAction,
) => {
  if (action.type === ADD_PRIORITY_STATE) {
    return {
      ...state,
      data: action.data,
    };
  }

  return state;
};
