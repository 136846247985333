import React from 'react';
import { useSelector } from 'react-redux';

import {
  ContentLogoProperties,
  ItemInfoProperties,
  RequestLogoProperties,
} from '../../interfaces/interfaces-codes-data';
import { OVERLAY_SETTINGS_STYLE } from '../../core/constants/constants';

import LogoComponent from './item-logo-component';
import styles from './item-result-info.module.scss';

const findLogo = (url: string, arrs: any) => {
  const currentLogo = arrs.find(
    (logoElement: RequestLogoProperties) => logoElement.id === url,
  );

  if (!currentLogo) {
    return false;
  }

  return currentLogo.value;
};

const ItemResultInfo = function ItemResultInfo({
  scanedCodeName,
  scanedCodeDescription,
  scanedCodeEventSponsorLogo,
  isShowSponsorsLogo,
  styleReference,
}: ItemInfoProperties): React.ReactElement {
  const altImage: string = 'Sponsor logo';
  const sponsorsTitle: string = 'Sponsored by';

  // eslint-disable-next-line react-redux/useSelector-prefer-selectors
  const sponsorLogosContent: any = useSelector(
    (state: { contentLogosState: ContentLogoProperties }) =>
      state.contentLogosState.content,
  );

  return (
    <div className={styles['a-item-info']}>
      <div
        className={styles['a-item-info__top-part']}
        style={{
          backgroundColor: styleReference.overlayTopColor,
          color: styleReference.ownerNameColor,
          fontFamily: styleReference.nameFont.name,

          fontStyle: styleReference.nameFont.isItalic
            ? OVERLAY_SETTINGS_STYLE.FONT_STYLE_ITALIC
            : OVERLAY_SETTINGS_STYLE.FONT_STYLE_NORMAL,

          fontWeight: styleReference.nameFont.isBold
            ? OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_BOLD
            : OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_NORMAL,
        }}
      >
        {scanedCodeName}
      </div>

      <div
        className={styles['a-item-info__bottom-part']}
        style={{
          backgroundColor: styleReference.overlayBottomColor,
          color: styleReference.ownerStatementColor,
          fontFamily: styleReference.statementFont.name,

          fontStyle: styleReference.statementFont.isItalic
            ? OVERLAY_SETTINGS_STYLE.FONT_STYLE_ITALIC
            : OVERLAY_SETTINGS_STYLE.FONT_STYLE_NORMAL,

          fontWeight: styleReference.statementFont.isBold
            ? OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_BOLD
            : OVERLAY_SETTINGS_STYLE.FONT_WEIGHT_NORMAL,
        }}
      >
        <div className={styles['a-item-info__bottom-part-top']}>
          {scanedCodeDescription}
        </div>

        {isShowSponsorsLogo && (
          <div className={styles['a-item-info__bottom-part-bottom']}>
            <div
              className={styles['a-item-info__title']}
              style={{
                color: styleReference.ownerStatementColor,
              }}
            >
              {sponsorsTitle}
            </div>

            {scanedCodeEventSponsorLogo.length > 0 &&
              scanedCodeEventSponsorLogo.map((element: any) => (
                <LogoComponent
                  key={element}
                  altImage={altImage}
                  source={findLogo(element, sponsorLogosContent)}
                />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ItemResultInfo;
