/* eslint-disable func-style,fp/no-mutation,react/jsx-no-literals,unicorn/consistent-function-scoping */
import React, { useEffect } from 'react';
import {
  configure,
  BarcodePicker as ScanditSDKBarcodePicker,
  ScanSettings,
} from 'scandit-sdk';

import { BarcodePickerInterface } from '../../interfaces/interfaces-codes-data';
import getScanSettings from '../../core/services/getScanSettings';

import styles from './barcode-picker.module.scss';

// eslint-disable-next-line fp/no-let,init-declarations
let barcode: ScanditSDKBarcodePicker;

function setBarcode(barcodeElement: ScanditSDKBarcodePicker) {
  barcode = barcodeElement;
}

const BarcodePicker: React.FC<BarcodePickerInterface | any> = function (
  props: any,
) {
  const { setDataScan } = props;

  const reference = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const key: any = process.env.REACT_APP_SCANDIT_LICENSE_KEY;

  const configurationPromise = configure(key, {
    engineLocation: process.env.REACT_APP_ENGINE_LOCATION,
  });

  const setBarcodePickerProperties = (
    barcodePicker: any,
    pickerProperties: BarcodePickerInterface,
    scanSettings: ScanSettings,
  ) => {
    if (
      [
        pickerProperties.isLaserArea,
        pickerProperties.isMirrorImageEnabled,
        scanSettings,
        pickerProperties.visible,
      ].some((property) => property === undefined)
    ) {
      // eslint-disable-next-line fp/no-throw
      throw new Error('Unexpected BarcodePicker Properties.');
    }

    barcodePicker.setGuiStyle(pickerProperties.isLaserArea);
    barcodePicker.setMirrorImageEnabled(pickerProperties.isMirrorImageEnabled);
    barcodePicker.setVisible(pickerProperties.visible);
    barcodePicker.setVideoFit('cover');
    barcodePicker.setTorchToggleEnabled(false);
    barcodePicker.setCameraSwitcherEnabled(true);
    barcodePicker.applyScanSettings(scanSettings);
  };

  const initialize = async () => {
    try {
      if (!reference.current && barcode) return;

      await configurationPromise;

      const barcodePicker = await ScanditSDKBarcodePicker.create(
        reference.current,
        props,
      );

      setBarcodePickerProperties(barcodePicker, props, getScanSettings());

      barcodePicker.on('scan', (scanData) => {
        const scanResult: any = scanData.barcodes[0].data;

        props.onScan([scanResult]);
      });
      barcodePicker.on('processFrame', (scanData) => {
        setDataScan(scanData);
      });

      setBarcode(barcodePicker);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // @ts-ignore
  useEffect(() => {
    if (barcode) return;

    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="sdk-wrapper-scan">
      <img
        alt="screenshot scanned"
        className={styles.image}
        id="pathBg"
        src=""
      />

      <div className={styles['sdk-wrapper']} id="sdk-wrapper">
        <div ref={reference} className={styles['sdk-container']} />
      </div>
    </div>
  );
};

export default BarcodePicker;
