import React from 'react';

import { EventProperties } from '../../interfaces/interfaces-codes-data';
import ScanedItemResultPremium from '../molecules/scaned-item-result-premium';

import styles from './overlay-result-premium.module.scss';

const OverlayPremium = function ({
  isShow,
  isShowSponsorsLogo,
  gotOverlay,
  currentEventId,
}: EventProperties): React.ReactElement {
  const idSdkOverlay = 'sdk_overlay';

  return (
    <div className={styles['o-overlay-result-premium']} id={idSdkOverlay}>
      {isShow && gotOverlay && gotOverlay.eventId === currentEventId && (
        <ScanedItemResultPremium
          isShowSponsorsLogo={isShowSponsorsLogo}
          item={gotOverlay}
          scanedCodeEventSponsorLogo={gotOverlay.eventSponsorLogo}
          stylesConfiguration={gotOverlay.stylesConfiguration}
        />
      )}
    </div>
  );
};

export default OverlayPremium;
